<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
            ref="document"
            id="ordenCompra"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Orden de Compra
                    </h3>
                  </div>
                    <h3 class="">
                      {{ brokerData.companyName }}
                    </h3>
                  <b-card-text class="mb-25">
                    {{ brokerData.address }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ brokerData.country }}
                  </b-card-text>
                  <!-- <b-card-text class="mb-0">
                    +52 (811) 456 7891, +52 (551) 543 2198
                  </b-card-text> -->
                </div>


                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div v-if="invoiceData.idOrder" class="d-flex justify-content-end mb-1">
                    <span class="title">
                      <h4>
                        Orden
                      <span class="invoice-number">#{{invoiceData.idOrder}}</span>
                      </h4>
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Fecha de creación:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.creationDateString"
                      class="form-control invoice-edit-input"
                      v-on:input="detectUnsavedChange"
                      :disabled="disableButtons"
                    />
                    <!-- FIXME ajustar el configTime -->
                      <!-- :config="configTime" -->
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Fecha de entrega estimada:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.estimatedDateString"
                      class="form-control invoice-edit-input"
                      v-on:input="detectUnsavedChange"
                      :disabled="disableButtons"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Fecha de entregado:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.deliveryDateString"
                      class="form-control invoice-edit-input"
                      v-on:input="detectUnsavedChange"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Cliente:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                    :options="companies"
                    label="companyName"
                    input-id="invoice-data-client"
                    :clearable="false"
                    v-on:input="detectUnsavedChange"
                    placeholder="Selecciona un proveedor"
                    @input='setClientData($event)'
                    v-model="invoiceData.companyData.companyName"
                    disabled
                  />
                </b-col>

                <!-- Col: Client Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block companyInfo"
                  style="justify-content: center!important;"
                >
                <!-- Selected Client -->
                  <div
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ companyData.companyName }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ companyData.address }} {{ companyData.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ companyData.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ companyData.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Item Section -->
            <b-card-body 
              v-if="!(Object.keys(companyData).length === 0)"
              class="invoice-padding form-item-section"
            >
              <div
                ref="form"
                class="repeater-form"
                :style="{height: 10}"
              >
                <b-row
                  v-for="(item, indexItem) in invoiceData.items"
                  :key="indexItem"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          Item
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cantidad (kgs)
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Precio
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Subtotal
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <!-- Item ROW -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-1">
                        <!-- METAL -->
                        <b-col lg="6" sm="12" id="metal-select">
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            :options="items"
                            label="alias"
                            :clearable="false"
                            class="item-selector-title"
                            placeholder="Selecciona un item"
                            :reduce="alias => alias.idItem"
                            v-model="invoiceData.items[indexItem].idItem"
                            @input='getCommissions($event, indexItem)'
                            v-on:input="detectUnsavedChange"
                            :disabled="disableButtons"
                          >
                            <span slot="no-options">Por favor, selecciona un cliente primero.</span>
                          </v-select>
                        </b-col>
                        <!-- Cantidad estimada-->
                        <b-col lg="2" sm="12" class="item-data">
                          <label class="d-inline d-lg-none">Cantidad(kgs)</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            @input="updateInput(indexItem, null, $event, 'itemQuantity')"
                            type="number"
                            v-model="item.quantity"
                            :state="validator.items[indexItem].quantity"
                            :disabled="disableButtons"
                            :formatter="limitMaxAmount"
                          />
                        </b-col>
                        <!-- Precio -->
                        <b-col lg="2" sm="12" class="item-data">
                          <label class="d-inline d-lg-none">Precio</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            @input="updateInput(indexItem, null, $event, 'itemPrice')"
                            type="number"
                            v-model="item.price"
                            :state="validator.items[indexItem].price"
                            :disabled="disableButtons"
                            :formatter="limitMaxAmount"
                          />
                        </b-col>
                        <!-- Subtotal -->
                        <b-col lg="2" sm="12" class="item-data">
                          <label class="d-inline d-lg-none">Subtotal</label>
                          <b-form-input
                            readonly
                            :value="calculatedValues(indexItem, null, 'itemSubTotal')"
                          />
                        </b-col>
                        <b-col cols="12" lg="12">
                          <center style="margin-top: 2em">
                            <h5 v-if="invoiceData.items[indexItem].commissions.length > 0">Comisiones por item</h5>
                          </center>
                        </b-col>
                        <b-col lg="2" sm="0" id="spacer">
                        </b-col>
                        <b-col 
                          v-for="(commission, indexCommission) in invoiceData.items[indexItem].commissions"
                          :key="indexCommission"
                          cols="10" 
                          lg="10"
                        >
                          <b-row class="flex-grow-1 p-1">
                            <b-col lg="4" sm="12" class="item-commission">
                              <b-card-text>
                              <b>
                                {{ commission.commission }}
                              </b>
                              </b-card-text>
                            </b-col>
                            <b-col lg="4" sm="12" class="item-commission">
                              <b-card-text>
                                $ {{ commission.commissionPrice }} por kg
                              </b-card-text>
                            </b-col>
                            <b-col lg="4" sm="12" class="item-commission">
                              <b-card-text>
                              $ {{calculatedValues(indexItem, indexCommission, 'commission')}}
                              </b-card-text>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <div v-if="!disableButtons" class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(indexItem)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="addNewItem()"
                id="add-item"
                :disabled="disableButtons"
              >
                Agregar item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body 
              v-if="!(Object.keys(companyData).length === 0)"
              class="invoice-padding pb-0"
            >
              <b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing">

                <!-- Col: Payment Type -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                >
                <b-card-body class="invoice-padding pt-0">
                  <b-form-group
                    label="Tipo de pago"
                    label-for="i-c-paymentType"
                  >
                    <v-select
                      :options="paymentTypes"
                      label="name"
                      input-id="invoice-data-paymentType"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      :reduce="name => name.id"
                      placeholder="Selecciona un tipo de pago"
                      @input='setPaymentType($event)'
                      v-model="invoiceData.paymentType"
                      disabled
                    />
                  </b-form-group>

                </b-card-body>
                </b-col>

                <!-- Col: Status -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                >
                <b-card-body class="invoice-padding pt-0">
                  <b-form-group
                    label="Estatus del pago"
                    label-for="i-c-paymentType"
                  >
                    <v-select
                      :options="paymentStatus"
                      label="name"
                      input-id="invoice-data-paymentType"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      :reduce="name => name.id"
                      v-model="invoiceData.idPaymentStatus"
                      @input='setPartialPayment($event)'
                      disabled
                    />
                  </b-form-group>

                  <b-form-group
                    label="Estatus del envio"
                    label-for="i-c-paymentType"
                  >
                    <v-select
                      :options="shippingStatus"
                      label="name"
                      input-id="invoice-data-paymentType"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      :reduce="name => name.id"
                      :disabled="disableButtons"
                      v-model="invoiceData.idShippingStatus"
                      @input='setShippingStatus($event)'
                    />
                  </b-form-group>
                </b-card-body>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-6 d-flex justify-content-center"
                >
                  <div class="invoice-total-wrapper" style="max-width: 13rem">
                    <div
                      v-if="invoiceData.balancesCommissions.length > 0"
                    >
                      <b>Comisionistas</b>
                      <div 
                      v-for="(balance, indexBalance) in invoiceData.balancesCommissions"
                      :key="indexBalance"
                      class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ balance.name }}
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(balance.idCommissionAgent, null, 'balancesCommissions')}}
                        </p>
                      </div>
                      <hr class="my-50">
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'subTotal')}}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Costo logísitca:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'logistic')}}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Comisiones:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'commissions')}}
                      </p>
                    </div>
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        IVA (16%):
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'iva')}}
                      </p>
                    </div> -->
                    <div v-if="invoiceData.maxAmount > 0">
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <b>Saldo a favor:</b>
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'abonado')}}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <b>Pendiente:</b>
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'pendiente')}}
                        </p>
                      </div>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'total')}}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Note -->
            <b-card-body 
              v-if="!(Object.keys(companyData).length === 0)"
              class="invoice-padding pt-0"
            >
              <span class="font-weight-bold">Nota: </span>
              <b-form-textarea
                v-on:input="detectUnsavedChange"
                v-model="invoiceData.note"
                :disabled="disableButtons"
              />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="12"
        xl="12"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card style="padding-top: 0.8em">
          <!-- Action Buttons -->
          <b-row>
            <!-- Button: Send Invoice -->
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mb-75"
                block
                @click="showModal"
                :disabled="saveButtonStatus || disableButtons"
                variant="primary"
              >
                <div v-if="saveButtonStatus">
                  <b-spinner small/>
                  &nbsp; Guardando ajuste...
                </div>
                <div v-else-if="!invoiceData.idOrder">Crear ajuste</div>
                <div v-else-if="invoiceData.idOrder">Orden guardada</div>
              </b-button>
            </b-col>
            <!-- Button: Download -->
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
                :disabled='invoiceData.idOrder == null'
                @click="printInvoice"
              >
                Imprimir
              </b-button>
            </b-col>
            <!-- Button: Print -->
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                disabled
              >
                Enviar por correo
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <span style="white-space: pre-line">Favor de llenar los campos requeridos faltantes:
          {{ missingParameters }}</span>
        </div>
      </b-alert>
    </b-modal>
    <!-- MODAL CONFIRMACIÓN -->
    <b-modal
      id="confirmModal"
      title="Confirmar"
      ok-title="Guardar orden"
      cancel-title="Voy a revisarlo"
      cancel-variant="outline-secondary"
      @ok="saveData()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <h3 style="margin-bottom: 1em">¿Seguro que deseas guardar esta orden?</h3>
      <p style="font-size: medium">Los datos ingresados no podrán ser editados.</p>
    </b-modal>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'


// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Catalogs
      items: [],
      companies: [],
      providers: [],
      companyData: {},
      paymentTypes: [
        {
          id: 3,
          name: 'Contado'
        },
        {
          id: 4,
          name: 'Crédito'
        },
        {
          id: 11,
          name: 'Ajuste'
        }
      ],
      shippingStatus: [
        {
          id: 5,
          name: 'Sin enviar'
        },
        {
          id: 6,
          name: 'En curso'
        },
        {
          id: 7,
          name: 'Entregado'
        }
      ],
      paymentStatus: [
        {
          id: 8,
          name: 'Sin pagar'
        },
        {
          id: 9,
          name: 'Parcialmente Pagado'
        },
        {
          id: 10,
          name: 'Pagado'
        }
      ],
      // Templates
      itemTemplate: {
        idItem: null,
        quantity: null,
        price: null,
        idMeasurementUnit: 1,
        subTotal: null,
        subTotalCommission: 0,
        commissions: [],
        providers: [
          {
            idProvider: null,
            estimatedQuantity: null,
            deliveryQuantity: null,
            estimatedDate: null,
            deliveryDate: null,
            price: null,
            discount: 0,
            subTotal: null,
          }
        ]
      },
      // Provider 
      providerTemplate: {
        idProvider: null,
        estimatedQuantity: null,
        deliveryQuantity: null,
        estimatedDate: null,
        deliveryDate: null,
        price: null,
        subTotal: null,
      },
      // Invoice
      invoiceData: {
        idOrder: null,
        creationDate: null,
        estimatedDate: null,
        deliveryDate: null,
        creationDateString: new Date(),
        estimatedDateString: null,
        deliveryDateString: null,
        idCompany: null,
        total: null,
        discount: 0,
        tax: null,
        idCountryTax: 1,
        subTotal: null,
        idQuote: null,
        idPaymentStatus: 8,
        idShippingStatus: 5,
        idUser: null,
        paymentType: 0,
        creditDays: 0,
        deadlinePayment: null,
        deadlinePaymentString: null,
        subTotalLogisticPrice: 0,
        logisticPrice: 0,
        orderBalance: 0,
        balancesCommissions: [],
        note: null,
        items: [],
        companyData: {
          idCompany: 0,
          companyName: null,
          companyEmail: null,
          contact: null,
          country: null,
          address: null,
        },
      },
      brokerData: {
        companyName: JSON.parse(localStorage.getItem('userData')).companyName,
        address: JSON.parse(localStorage.getItem('userData')).address,
        country: JSON.parse(localStorage.getItem('userData')).country,
      },

      // Props
      showCreditDays: false,
      showPartialPayment: false,
      saveButtonStatus: false,
      byPassRouteLeave: true,
      disableButtons: false,

      // Validations for b-form-input's state
      validator: {
        items: [],
        orderBalance: null,
      },
      validatorItemTemplate: {
        quantity: null,
        price: null,
      },
      missingParameters: '',

      // TODO
      configTime: {
        dateFormat: 'd-m-Y',
        locale: Spanish, // locale for this instance only          
      },

    }
  },
  created() {
    this.getProviders();
  },
  beforeRouteLeave(to, from, next) { // Evitar que se salgan de la pantalla y perder datos
    if (!(this.byPassRouteLeave)) {
      const answer = window.confirm('¿Seguro que desea salir? Los cambios no guardados se perderán.');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    setDataOrder(){
        this.invoiceData.paymentType = 11
        this.setPaymentType(this.invoiceData.paymentType);
        this.invoiceData.idPaymentStatus = 10;
        this.invoiceData.maxAmount = Math.abs(router.currentRoute.params.balance);

        const companyData = this.companies.find(company => company.idCompany == router.currentRoute.params.id);
        this.setClientData(companyData);
    },
    getProviders() {
      this.$axios.get(this.$services + 'providers/get_providers').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.companies = res.data.data;
          this.setDataOrder();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    setClientData(value) {
      this.companyData = value;
      this.getItemsByClient(value.idCompany);
      this.invoiceData.idCompany = value.idCompany;
      this.invoiceData.companyData = value;
      this.invoiceData.items = [];
      this.addNewItem();
    },
    setPaymentType(value) {
      this.invoiceData.paymentType = value;
      this.showCreditDays = false;
      this.showPartialPayment = false;
      this.invoiceData.creditDays = 0;
      this.invoiceData.deadlinePayment = null;
      this.invoiceData.deadlinePaymentString = null;
      this.invoiceData.idPaymentStatus = 10;
      this.invoiceData.idShippingStatus = 5;
    },
    setShippingStatus(value) {
      if (value == 7) {
        this.invoiceData.estimatedDateString = this.$moment().format("DD/MM/YYYY");
        this.invoiceData.deliveryDateString = this.$moment().format("DD/MM/YYYY");
      } else {
        this.invoiceData.estimatedDateString = null;
        this.invoiceData.deliveryDateString = null;
      }
    },
    getItemsByClient(idCompany) {
      this.$axios.get(this.$services + 'general/get_metals_commissions_client', {
        params: {
          idCompany: idCompany,
          role: 'PROVEEDOR',
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        this.items = [];
        if (!res.data.error) {
          this.items = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {});
    },
    getCommissions(idItem , index) {
      let item = this.items.filter(items => items.idItem == idItem);

      this.invoiceData.logisticPrice = item[0].logisticPrice;
      this.invoiceData.idQuote = item[0].idQuote;

      let commissions = 0;
      
      item[0].commissions.forEach(element => {
        commissions += element.commissionPrice;

        this.invoiceData.items[index].commissions.push(element);
      });

      this.invoiceData.items[index].price = utils.redondeo(item[0].selectedPrice - this.invoiceData.logisticPrice - commissions - item[0].increment + item[0].decrement);
    },
    showModal() {

      if (this.checkMissingParameters()) {
        this.$bvModal.show('alertModal');
        this.saveButtonStatus = false;
        return;
      }

      this.$bvModal.show('confirmModal');
    },
    saveData() {

      // Date format
      this.invoiceData.creationDate = this.$moment(this.invoiceData.creationDateString).valueOf();
      this.invoiceData.estimatedDate = this.$moment(this.invoiceData.estimatedDateString).valueOf();
      this.invoiceData.deliveryDate = this.invoiceData.deliveryDateString ? this.$moment(this.invoiceData.deliveryDateString).valueOf() : null;
      ;

      // Items
      this.invoiceData.items.forEach((element, indexItem) => {
        // Providers by Items
        this.invoiceData.items[indexItem].providers.forEach((element, indexProvider) => {
          this.invoiceData.items[indexItem].providers[indexProvider].deliveryDate = this.$moment(element.deliveryDateString).valueOf();
          this.invoiceData.items[indexItem].providers[indexProvider].estimatedDate = this.$moment(element.estimatedDateString).valueOf();
        });
      });

      this.saveButtonStatus = true;
      if (this.invoiceData.idOrder) {
        this.updateOrder();
      } else {
        this.createOrder();
        this.disableButtons = true;
      }
    },
    createOrder() {
      this.$axios.post(this.$services+'orders/create_purchase_order', {
        invoiceData: this.invoiceData,
        isProvider: false
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', String('¡Orden guardada con éxito!'));
          this.saveButtonStatus = false;
          this.invoiceData.idOrder = res.data.data.idOrder;
        } else {
        }
      }).catch(error => {
        this.showToast('danger', String(error));
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.saveButtonStatus = false;
      });
    },
    updateInput(indexItem, indexProvider, value, type){ // Inputs de precios y cantidades

      let inputValue = parseFloat(value); // Parse al dato del input

      if (isNaN(inputValue)) { // Input es NaN
        inputValue = 0;
      }

      // CLIENTE
      if (type === 'itemQuantity') {
        this.invoiceData.items[indexItem].quantity = inputValue;
      } else if (type === 'itemPrice') {
        this.invoiceData.items[indexItem].price = inputValue;
      } 
    },
    calculatedValues(indexItem, indexCommission, type){ // Calcula el subtotal
      let value = 0;

      if (type === 'itemSubTotal') {

        value = this.invoiceData.items[indexItem].price * this.invoiceData.items[indexItem].quantity;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.items[indexItem].subTotal = value;

        return "$ " + utils.numberWithCommas(value);
      } else if (type === 'totalQuantityItem') {

        this.invoiceData.items[indexItem].providers.forEach(element => {
          value += element.deliveryQuantity;
        });

        return value;
      } else if (type === 'subTotal') {

        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });

        value = parseFloat(value.toFixed(2));

        this.invoiceData.subTotal = value;
        this.invoiceData.orderBalance = value;

        return utils.numberWithCommas(value);
      } else if (type === 'iva') {

        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });

        value *= 0.16;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.tax = value;

        return utils.numberWithCommas(value);
      } else if (type === 'total') {

        let subtotal = 0, commissions = 0, logistic;

        // subtotal
        this.invoiceData.items.forEach(element => {
          subtotal += element.subTotal;
        });
        value += subtotal;

        // Si el pago es de contado, el total son las comisiones
        if (this.invoiceData.paymentType === 3)
          this.invoiceData.orderBalance = subtotal;

        // commissions
        this.invoiceData.items.forEach(element => {
          commissions += element.subTotalCommission;
        });
        value += commissions;

        // logisticPrice
        logistic = this.invoiceData.subTotalLogisticPrice;
        value += logistic;

        // abonado
        if (this.invoiceData.orderBalance <= this.invoiceData.subTotal)
          value -= this.invoiceData.orderBalance;
        else
          value -= this.invoiceData.subTotal;
        // value *= 1.16;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.total = value;

        return utils.numberWithCommas(value);
      } else if (type === 'pendiente') {

        // pendiente
        if (this.invoiceData.maxAmount >= this.invoiceData.subTotal)
          value = this.invoiceData.maxAmount - this.invoiceData.subTotal;
        else
          value = 0;

        return utils.numberWithCommas(value);
      } else if (type === 'abonado') {

        // abonado
        value = this.invoiceData.maxAmount == null ? 0 : this.invoiceData.maxAmount;

        return utils.numberWithCommas(value);
      } else if (type === 'commission') {
      
        value = this.invoiceData.items[indexItem].commissions[indexCommission].commissionPrice * this.invoiceData.items[indexItem].quantity;

        let idCommissionAgent = this.invoiceData.items[indexItem].commissions[indexCommission].idCommissionAgent;

        let index = this.invoiceData.balancesCommissions.findIndex(balance => 
          balance.idCommissionAgent == idCommissionAgent
        );

        if (index < 0) {

          let deadline;
          let deadlineDay = this.invoiceData.items[indexItem].commissions[indexCommission].deadlineDay;

          if (this.$moment().day() >= deadlineDay)
            deadline = this.$moment().day(deadlineDay + 7).set({hour:0,minute:0,second:0,millisecond:0}).valueOf();
          else
            deadline = this.$moment().day(deadlineDay).set({hour:0,minute:0,second:0,millisecond:0}).valueOf();

          let obj = {
            idCommissionAgent: idCommissionAgent,
            deadline: deadline,
            balance: 0,
            name: this.invoiceData.items[indexItem].commissions[indexCommission].agentName
          }
          this.invoiceData.balancesCommissions.push(obj)
        }

        value = parseFloat(value.toFixed(2));

        this.invoiceData.items[indexItem].subTotalCommission = value;

        return utils.numberWithCommas(value);
      } else if (type === 'commissions') {

        this.invoiceData.items.forEach(element => {
          value += element.subTotalCommission;
        });

        value = parseFloat(value.toFixed(2));

        return utils.numberWithCommas(value);
      } else if (type === 'logistic') {

        let quantity = 0;

        this.invoiceData.items.forEach(element => {
          quantity += element.quantity;
        });

        value = this.invoiceData.logisticPrice * quantity;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.subTotalLogisticPrice = value;

        return utils.numberWithCommas(value);
      } else if (type === 'balancesCommissions') {

        let idCommissionAgent = indexItem;

        this.invoiceData.items.forEach(item => {
          item.commissions.forEach(commission => {
            if (commission.idCommissionAgent == idCommissionAgent){
              value += commission.commissionPrice * item.quantity;
            }
          });
        });

        value = parseFloat(value.toFixed(2));

        let index = this.invoiceData.balancesCommissions.findIndex(balance => 
          balance.idCommissionAgent == idCommissionAgent
        );

        this.invoiceData.balancesCommissions[index].balance = value;

        return utils.numberWithCommas(value);
      } else {
        return null;
      }    
    },
    checkMissingParameters() {

      // Default
      let missing = false;
      this.missingParameters = '\n\n';

      // Checar fechas
      if (
        this.invoiceData.creationDateString == null ||
        this.invoiceData.creationDateString === ''
      ) {
        missing = true;
        this.missingParameters += '• Fecha de creación\n';
      }

      if (
        this.invoiceData.estimatedDateString == null ||
        this.invoiceData.estimatedDateString === ''
      ) {
        missing = true;
        this.missingParameters += '• Fecha de entrega estimada\n';
      }

      // Checar cliente
      if (this.invoiceData.idCompany == null) {
        missing = true;
        this.missingParameters += '• Cliente\n';
      }

      // Al menos un item en la orden
      if (this.invoiceData.items.length == 0) {
        missing = true;
        this.missingParameters += '• Agregar al menos un item \n';
      }

      // Checar item/s
      for (const [index, item] of this.invoiceData.items.entries()) {
        if (item.idItem == null) {
          missing = true;
          this.missingParameters += '• Nombre del item núm. ' + (index+1) + '\n';
        }

        if (
          item.quantity == null ||
          item.quantity === 0
        ) {
          missing = true;
          this.missingParameters += '• Cantidad del item núm. ' + (index+1) + '\n';
          this.validator.items[index].quantity = false;
        } else {
          this.validator.items[index].quantity = null;
        }

        if (
          item.price == null ||
          item.price === 0
        ) {
          missing = true;
          this.missingParameters += '• Precio del item núm. ' + (index+1) + '\n';
          this.validator.items[index].price = false;
        } else {
          this.validator.items[index].price = null;
        }
      }

      // Checar menús inferiores
      if (this.invoiceData.paymentType == 0) {
        missing = true;
        this.missingParameters += '• Tipo de pago\n';
      }

      // if (this.invoiceData.paymentType == 4) {
      //   if (
      //     this.invoiceData.creditDays == null ||
      //     this.invoiceData.creditDays === ''
      //   ) {
      //     missing = true;
      //     this.missingParameters += '• Días de crédito\n';
      //     this.validator.creditDays = false;
      //   } else {
      //     this.validator.creditDays = null;
      //   }
      // }

      if (this.invoiceData.idPaymentStatus == 9) {
        if (
          this.invoiceData.orderBalance == 0 ||
          this.invoiceData.orderBalance === '' ||
          this.invoiceData.orderBalance === null
        ) {
          missing = true;
          this.missingParameters += '• Cantidad abonada\n';
          this.validator.orderBalance = false;
        } else {
          this.validator.orderBalance = null;
        }
      }

      return missing;  // No falta ningún parámetro
    },
    addNewItem() {
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemTemplate)));
      this.validator.items.push(JSON.parse(JSON.stringify(this.validatorItemTemplate)));
    },
    removeItem(indexItem) {
      this.invoiceData.items.splice(indexItem, 1);
      this.validator.items.splice(indexItem, 1);
    },
    detectUnsavedChange() {
      this.byPassRouteLeave = false;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event) { // Sólo números pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
        return;
      }
      this.byPassRouteLeave = false;
    },
    printInvoice() {
      let tempTitle = document.title;
      document.title = 'Orden_Compra_#'+this.invoiceData.idOrder+'.pdf';
      window.print();
      document.title = tempTitle;
    },
    limitMaxAmount(e){

      // Suma subtotal
      let value = null;
      this.invoiceData.items.forEach(element => {
        value += (element.quantity * element.price);
      });

      if (value > this.invoiceData.maxAmount) {
        return e.slice(0, -1);
      } else if (parseInt(e) < 0) {
        return 0;
      } else {
        return e;
      }
    },
  },
  computed: {
  },
}
</script>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  #add-item {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .companyInfo {
    display: flex !important;
  }

  // Items
  #metal-select {
    flex: 0 0 auto;
    width: 50%;
  }

  .item-data {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  #spacer {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .item-commission {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

}
</style>
